<template>
  <div id="VideoPage">
    <div class="row">
      <div v-if="title == ''" class="card">
        <div class="card_header ssc">
          <div class="ssc-head-line w-80"></div>
          <div class="tool_bar ssc">
            <div class="ssc-line w-60" style="margin-top: 20px"></div>
          </div>
        </div>
        <div class="card_body ssc">
          <div
            class="search-ssc-lg__tag ssc-square"
            style="margin-top: 30px; height: 400px"
          ></div>

          <div class="ssc-line w-60" style="margin-top: 30px"></div>
          <div class="ssc-line w-40"></div>
        </div>
      </div>
      <div v-else class="card">
        <div class="card_header">
          <h2 class="title_font">{{ title }}</h2>
          <div class="tool_bar">
            <p class="date sub_content_font">on {{ date }}</p>

            <div class="share_box">
              <p class="sub_content_font">分享</p>
              <a @click="ShareToLine"><img src="@/assets/line.png"/></a>
              <a @click="ShareToFB"><img src="@/assets/facebook.png"/></a>
            </div>
          </div>
        </div>
        <div class="cover_img">
          <!-- <img :src="GetFullPath(this.cover)" /> -->
          <div class="video_block" v-html="link"></div>
        </div>
        <div class="card_body content_font" v-html="content"></div>
      </div>
    </div>
    <div class="news_bottom">
      <p class="sub_title_font">了解更多宏信五金行影音內容</p>
      <button class="sub_content_font" @click="$router.push('/video_center')">
        回到影音專區列表
      </button>
    </div>

    <div class="share_group" :class="{ active: share_group }">
      <button class="main" @click="share_group = !share_group">
        <span class="material-icons-outlined"> </span>
      </button>
      <button class="facebook" @click="ShareToFB">
        <img src="@/assets/line.png" />
      </button>
      <button class="line" @click="ShareToLine">
        <img src="@/assets/facebook.png" />
      </button>
    </div>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'VideoPage',
  data() {
    return {
      share_group: false,
      title: '',
      link: '',
      date: '',
      content: '',
      cover: '',
      meta_data: null,
    };
  },
  methods: {
    async LoadData(id) {
      this.$store.commit('SetLoading', true);
      let response = await this.GetData('/video');
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        let now_video = response.filter((item) => item.VideoID == id);
        if (now_video.length > 0) {
          now_video = now_video[0];
          this.title = now_video.Title;
          this.link = now_video.Link.split('watch?v=')[1];
          this.link = `<iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/${this.link}"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>`;
          this.date = now_video.NewTime;
          this.content = now_video.Content;
          this.cover = now_video.Image;

          this.meta_data = GetMetaData(
            this.title,
            this.content.replace(/<[^>]+>/g, '').substring(0, 150),
            this.GetFullPath(this.cover)
          );
          this.$nextTick(() => {
            window.prerenderReady = true;
          });
        } else {
          ReturnErrorPage();
        }
      }
    },
    ShareToFB() {
      window
        .open(
          `https://www.facebook.com/sharer.php?u=${window.location.href}`,
          '_blank'
        )
        .focus();
    },
    ShareToLine() {
      window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
    },
  },
  created() {
    this.LoadData(this.$route.params.id);
  },
  metaInfo() {
    return this.meta_data;
  },
  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': window.location.href,
      },
      headline: this.title,
      image: [this.GetFullPath(this.cover)],
      datePublished: this.date,
      dateModified: this.date,
      author: {
        '@type': 'Person',
        name: '林泳欣',
      },
      publisher: {
        '@type': 'Organization',
        name: '宏信五金行',
        logo: {
          '@type': 'ImageObject',
          url: 'https://www.hongsyn.com/img/logo.svg',
        },
      },
    };
  },
};
</script>
